import axios from '../common/axios.js'
import _ from 'lodash'
import CustomInput from '../common/forms/Input.vue'

export default {
	data() {
		return {
			user: null,
			errors: {},
			success: false,
			telechargements: {},
			annee: 0,
			telechargement: {},
			noEditSourcings: ['grandest','ecotlctest','ecotlc','refashion']
			// noEditSourcings: ['ecotlctest','ecotlc','refashion']
		}
	},
	created(){
		this.user = _.cloneDeep(this.$store.state.user.user)
		let sourcing_id = this.$store.state.ui.sourcing.id;
		let self = this
		axios.get('api=inex/views/connections-credits/{view_code}')
			.then(function (response) {
				if (response.data.annees.length) {
					self.telechargements = response.data
					self.annee = response.data.annees[response.data.annees.length-1]
				}
			}).catch(function (error) {

			})
	},
	methods: {
		checkError(code){
			return 'aaa'
		},
		close_filter(){
			this.$store.commit('UI_SET_CONTENT', {content: 'result'});
		},
		save_user(){
			let self = this

			self.errors = {}

			let data = new FormData();
			if(this.canEdit){
				data.append('nom', self.user.nom);
				data.append('prenom', self.user.prenom);
				data.append('email', self.user.email);
				data.append('fonction', self.user.fonction);
				data.append('password', self.user.password);
				data.append('passwordb', self.user.passwordb);
			}
			data.append('langue', self.user.langue);

			this.$store.commit('UI_SET_SAVE_USER_LOADING', true);

			axios.post('/plugin_projet/plugin_projet_iannuaire/update_user', data)
				.then(function (response) {
					if (response.data == 'OK') {
						self.user.password = ''
						self.user.passwordb = ''
						self.$store.dispatch('update_user', self.user)
						self.success = true
						setTimeout(() => self.success = false, 2000)

						if(self.user.langue != self.$store.state.ui.langue){
							self.$store.commit('UI_SET_LANGUE', self.user.langue);
							self.$store.dispatch('uploadInitApp');
						}else{
							self.$store.commit('UI_SET_SAVE_USER_LOADING', false);
						}

					} else {
						self.errors = response.data
						self.$store.commit('UI_SET_SAVE_USER_LOADING', false);
					}
				}).catch(function (error) {

				})
		},		
		checkLoginError(){
			axios.get('api=inex/login/error')
		}
	},
	computed:{
		getTelechargement(){
			return this.annee ? this.telechargements.data_by_years_months[this.annee] : {}
		},
		getTotal1(){
			return this.annee ? this.telechargements.data_by_years[this.annee]['total_month'] : 0;
		},
		getTotal2(){
			return this.annee ? this.telechargements.data_by_years[this.annee]['uniq'] : 0;
		},
		getTotal3(){
			return this.annee ? this.telechargements.data_by_years[this.annee]['new'] : 0;
		},
		canEdit(){
			//AS--> Bloque l'édition de tous les sourcing définis
			return !this.noEditSourcings.includes(this.$store.state.ui.sourcing.url_sourcing);
		}
	},
	components:{
		SousMenu: () => import('./sous_menu.vue'),
		CustomInput
	}
}